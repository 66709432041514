<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createConnectionContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Edit Content</h3>
              </div>
            </div>

            <b-row id="processContentForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">
                            <div class="col-lg-12">
                              <div class="row form-container">
                                <div class="col-lg-12">
                                  <h1>Black Card Content</h1>
                                  <br />
                                  <b-row v-if="ckeditorReady == false">
                                    <div class="col-lg-12 text-center">
                                      <pulse-loader
                                        color="var(--pulse-loader)"
                                      ></pulse-loader>
                                      <b>Loading...</b>
                                    </div>
                                  </b-row>
                                  <b-form-group
                                    v-else
                                    role="group"
                                    class="mb-3 has-feedback"
                                  >
                                    <!-- <label class="w-100 form-control-label pb-2" for="content">Content</label> -->
                                    <ckeditor
                                      v-if="ckeditorReady"
                                      id="contentBlackCard"
                                      name="content"
                                      :editor="editor"
                                      v-model="content"
                                      :config="editorConfig"
                                      v-validate="'required'"
                                      data-vv-name="content"
                                      data-vv-as="content"
                                      data-vv-scope="content"
                                      class="no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('content'),
                                        'has-invalid': errors.has('content'),
                                      }"
                                    >
                                    </ckeditor>
                                    <span class="has-icon-feedback"></span>
                                    <div
                                      class="invalid-feedback"
                                      :is-invalid="errors.has('content')"
                                    >
                                      {{ errors.first("content") }}
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <!-- <router-link ref="clear" :to="`/black-card`" exact class="btn btn-outline-primary pr-5 pl-5 w-sm-100">Cancel</router-link> -->
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button
                                type="button"
                                id="processContent"
                                @click="updateContent()"
                                class="
                                  btn btn-primary
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                "
                              >
                                Update Content
                                <span
                                  class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import moment from "moment";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";

import Link from "@ckeditor/ckeditor5-link/src/link";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import List from "@ckeditor/ckeditor5-list/src/list";
import Font from "@ckeditor/ckeditor5-font/src/font";
import BlockToolbar from "@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import ParagraphButtonUI from "@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";

import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";

import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import CloudServices from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";

import { API_URL } from "@/api/constants/baseUrl";
import config from "config";
import { decoder } from "@/utils/sessions/jwt";

Vue.use(Cookies);

export default {
  data() {
    return {
      action: "create",
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EasyImage,
          EssentialsPlugin,
          Heading,
          BoldPlugin,
          ItalicPlugin,
          BlockQuote,
          Underline,
          LinkPlugin,
          ParagraphPlugin,
          Link,
          List,
          Alignment,
          Indent,
          IndentBlock,
          Font,
          HeadingButtonsUI,
          ParagraphButtonUI,
          Alignment,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          ImageUpload,
          CloudServices,
          Autoformat,
        ],
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "blockQuote",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "outdent",
          "indent",
          "|",
          "undo",
          "redo",
          "|",
          "link",
          "|",
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "alignment:left",
          "alignment:right",
          "alignment:center",
          "alignment:justify",
          "|",
          "imageUpload",
          "imageStyle:side",
          "imageStyle:full",
        ],
        image: {
          resizeOptions: [
            {
              name: "resizeImage:original",
              value: null,
              icon: "original",
            },
            {
              name: "resizeImage:50",
              value: "50",
              icon: "medium",
            },
            {
              name: "resizeImage:75",
              value: "75",
              icon: "large",
            },
          ],
          toolbar: [
            "imageTextAlternative",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "resizeImage:50",
            "resizeImage:75",
            "resizeImage:original",
          ],
          styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
        indentBlock: {
          classes: [
            "custom-block-indent-a", // First step - smallest indentation.
            "custom-block-indent-b",
            "custom-block-indent-c", // Last step - biggest indentation.
          ],
        },
        cloudServices: {
          tokenUrl: API_URL + "/endpoint",
          uploadUrl: "https://79127.cke-cs.com/easyimage/upload/",
        },
      },
      content: "",
      formData: new FormData(),
      ckeditorReady: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.blackcard.loading;
    },
  },
  methods: {
    getBlackCardContent() {
      this.$store.dispatch("blackcard/GET_CONTENT").then((content) => {
        this.content = content.content;
        this.ckeditorReady = true;
      });
    },
    updateContent() {
      const promiseBtn = document.getElementById("processContent");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processContentForm");
      bodyWrapper.classList.add("non-clickable");

      let content = this.content;

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch("blackcard/UPDATE_CONTENT", { content: content })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Black Card Content successfully updated"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                // this.$router.push('/black-card')
                this.getBlackCardContent();
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Connection already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating connection"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }

              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              });
            });
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.getBlackCardContent();
  },
};
</script>
<style lang="scss">
.ck-content {
  background: #000000 !important;
}
</style>
