<template>
  <div class="videos">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <!-- HEADER -->
        <div slot="header" class="row align-items-center">
          <div class="col-lg-6 col-8">
            <h3 class="mb-0">Home Featured Content</h3>
          </div>
          <div class="col-lg-6 pull-left">
            <a
              @click.prevent="composeVideo"
              href="#"
              class="btn btn-primary opacity-8 float-right mx-2"
            >
              <i class="fa fa-plus-circle"></i>
              New Video
            </a>
            <a
              @click.prevent="composeImage"
              href="#"
              class="btn btn-primary opacity-8 float-right mx-2"
            >
              <i class="fa fa-plus-circle"></i>
              New Image
            </a>
          </div>
        </div>

        <!-- MAIN CONTENTS -->
        <div class="card-body" v-if="$store.state.home.loading">
          <pulse-loader color="var(--pulse-loader)"></pulse-loader>
        </div>
        <div class="card-body" v-else>
          <div class="form-group" style="max-width: 300px">
            <label class="form-control-label">Select City</label>
            <b-form-select
              v-model="city"
              :options="cities.map((c) => ({ text: c.city, value: c._id }))"
              required
            >
            </b-form-select>
          </div>

          <div class="row">
            <div class="col-lg-12" v-if="contents.length === 0">
              <p class="text-center">No contents yet.</p>
            </div>
            <div class="col-lg-12" v-else>
              <h2 class="divider-title">
                <i
                  @click="showTop = !showTop"
                  class="toggleIcon"
                  :class="{
                    'fas fa-toggle-on': showTop,
                    'fas fa-toggle-off': !showTop,
                  }"
                ></i>

                Top Featured Content
              </h2>
              <div v-if="showTop" class="featureContainer">
                <div v-if="topFeatured.length == 0" class="no-content">
                  <p class="text-center">No contents yet.</p>
                </div>
                <div class="row">
                  <div
                    class="col-lg-4"
                    v-for="(content, i) in topFeatured"
                    :key="i"
                  >
                    <div class="card">
                      <video
                        v-if="content.content.type == 'video'"
                        preload="metadata"
                        :src="content.content.media"
                        @mouseover="$event.target.controls = true"
                        @mouseleave="$event.target.controls = false"
                      >
                        Your browser does not support video tag.
                      </video>

                      <div
                        class="form-group"
                        v-if="content.content.type == 'image'"
                      >
                        <img width="100%" :src="content.content.media" alt="" />
                      </div>
                      <button
                        class="btn btn-sm btn-white"
                        @click="manageContent(content)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h2 class="divider-title">
                <i
                  @click="showBottom = !showBottom"
                  class="toggleIcon"
                  :class="{
                    'fas fa-toggle-on': showBottom,
                    'fas fa-toggle-off': !showBottom,
                  }"
                ></i>
                Bottom Featured Content
              </h2>
              <div v-if="showBottom" class="featureContainer">
                <div v-if="bottomFeatured.length == 0" class="no-content">
                  <p class="text-center">No contents yet.</p>
                </div>
                <div class="row">
                  <div
                    class="col-lg-4"
                    v-for="(content, i) in bottomFeatured"
                    :key="i"
                  >
                    <div class="card">
                      <video
                        v-if="content.content.type == 'video'"
                        preload="metadata"
                        :src="content.content.media"
                        @mouseover="$event.target.controls = true"
                        @mouseleave="$event.target.controls = false"
                      >
                        Your browser does not support video tag.
                      </video>

                      <div
                        class="form-group"
                        v-if="content.content.type == 'image'"
                      >
                        <img width="100%" :src="content.content.media" alt="" />
                      </div>
                      <button
                        class="btn btn-sm btn-white"
                        @click="manageContent(content)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>

    <b-modal
      v-model="modal"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      centered
    >
      <template v-slot:modal-title>
        <span style="color: #000">{{ openedModal }}</span>
      </template>
      <div class="card-body" v-if="$store.state.home.loading">
        <pulse-loader color="var(--pulse-loader)"></pulse-loader>
      </div>
      <div v-else>
        <!-- VIDEO -->

        <!-- If composeVideo, Display this Preview -->
        <div
          class="form-group"
          v-if="content.content.media && openedModal == 'Video'"
        >
          <video
            preload="metadata"
            class="w-100"
            :src="content.content.media"
            controls
          >
            Your browser does not support video tag.
          </video>
        </div>

        <!-- If composeVideo, Display this Form -->
        <div v-if="openedModal == 'Video'" class="form-group">
          <label class="form-control-label">
            {{ content._id ? "Change Video" : "Video" }}
          </label>
          <b-form-file
            v-model="content.newContent"
            placeholder="Browse file or drop here..."
            drop-placeholder="Drop file here..."
            accept="video/mp4,video/x-m4v,video/*"
          ></b-form-file>
        </div>

        <!-- Thumbnail for Video START -->
        <div
          class="form-group"
          v-if="
            content.content.thumbnail &&
            openedModal == 'Video' &&
            actionType == 'Update' &&
            !content.newThumbnail
          "
        >
          <img width="100%" :src="content.content.thumbnail" alt="" />
        </div>

        <cropper
          ref="cropper"
          v-if="openedModal == 'Video'"
          class="cropper"
          :src="content.content.newThumbnail"
          :stencil-props="{
            aspectRatio: 4 / 3,
          }"
        />

        <div v-if="openedModal == 'Video'" class="form-group">
          <label class="form-control-label">
            {{ content._id ? "Change Thumbnail" : "Thumbnail" }}
          </label>

          <b-form-file
            v-model="content.newThumbnail"
            placeholder="Browse file or drop here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <h5 class="mt-3">
            <i>Please use 4:3 aspect ratio for best results.</i>
          </h5>
        </div>
        <!-- Thumbnail for Video END -->

        <!-- IMAGE -->

        <!-- If composeImage, Display this Preview -->
        <div
          v-if="
            content.content.media &&
            openedModal == 'Image' &&
            actionType == 'Update' &&
            !content.newContent
          "
        >
          <label class="w-100 form-control-label" for="url">Original</label>
          <div class="form-group">
            <img width="100%" :src="content.content.media" alt="" />
          </div>
        </div>

        <!-- If composeImage, Display this Cropper -->
        <div v-if="content.content.media && openedModal == 'Image'">
          <label class="w-100 form-control-label" for="url">Preview</label>
          <cropper
            ref="cover"
            v-if="openedModal == 'Image'"
            class="cropper"
            :src="content.content.media"
            :stencil-props="{
              aspectRatio: 4 / 3,
            }"
            @change="onChangeMedia"
          />
        </div>

        <!-- If composeImage, Display this Form -->
        <div v-if="openedModal == 'Image'" class="form-group">
          <label class="form-control-label">
            {{ content._id ? "Change Image" : "Image" }}
          </label>
          <b-form-file
            v-model="content.newContent"
            placeholder="Browse file or drop here..."
            drop-placeholder="Drop file here..."
            accept="image/x-png,image/gif,image/jpeg"
          ></b-form-file>
          <h5 class="mt-3">
            <i>Please use 4:3 aspect ratio for best results.</i>
          </h5>
        </div>

        <!-- <label v-if="content.content.preview && !content.newContent" class="w-100 form-control-label" for="url">Media Preview</label> -->
        <!-- <div v-if="content.content.preview && !content.newContent" class="mb-3">
          <img width="100%" :src="content.content.preview" alt="">
        </div> -->
        <div v-if="updatedPreview" class="mb-3">
          <label class="form-control-label">Media Preview</label>
          <img width="100%" :src="updatedPreview" alt="" />
        </div>

        <!-- Enter Url  -->
        <b-form-group role="group" class="mb-3 has-feedback">
          <label class="w-100 form-control-label" for="url">Content Url</label>
          <input
            type="text"
            name="url"
            v-model.trim="content.content.url"
            data-vv-as="aricle url"
            data-vv-name="url"
            placeholder="https://www.example.com"
            autocomplete="NoAutocomplete"
            class="form-control no-instruction"
            :class="{
              input: true,
              'is-invalid': errors.has('urls'),
              'has-invalid': errors.has('urls'),
            }"
          />
          <span class="has-icon-feedback"></span>
          <div class="invalid-feedback" :is-invalid="errors.has('urls')">
            {{ errors.first("urls") }}
          </div>
        </b-form-group>
        <!-- Select display position -->
        <b-form-group role="group" class="mb-3 has-feedback">
          <label class="w-100 form-control-label" for="position"
            >Select Featured Position
          </label>
          <b-form-select
            v-model="content.position"
            :options="positions"
            name="position"
            placeholder="Please select position"
            v-validate="'required'"
            data-vv-as="position"
            data-vv-name="position"
            class="form-control no-instruction custom-select"
          >
          </b-form-select>
        </b-form-group>
      </div>
      <template v-slot:modal-footer>
        <base-button
          type="danger"
          v-if="content._id"
          @click="deleteContent"
          :disabled="$store.state.home.loading"
        >
          Delete
        </base-button>
        <div style="flex: 1"></div>
        <base-button type="flat" @click="modal = false">Cancel</base-button>
        <base-button
          type="default"
          class="btn-aqua"
          @click="content._id ? updateContent() : uploadContent()"
          :disabled="$store.state.home.loading"
        >
          {{ content._id ? `Update ${openedModal}` : `Upload ${openedModal}` }}
        </base-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper,
  },
  data() {
    return {
      actionType: null,
      showTop: true,
      showBottom: true,
      city: null,
      modal: false,
      video: {
        _id: null,
        city: null,
        url: null,
        newVideo: null,
      },
      content: {
        _id: null,
        city: null,
        content: {
          media: null,
          preview: null,
          thumbnail: null,
          previewCoordinates: null,
          type: null,
          url: null,
        },
        position: null,
        newContent: null,
        newThumbnail: null,
      },
      limitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 240,
        minHeight: 240,
      },
      openedModal: null,
      positions: [
        { value: null, text: "Please select position", disabled: true },
        { value: 1, text: "Top" },
        { value: 0, text: "Bottom" },
      ],
      // hasTopFeatured:null,
      // hasBottomFeatured:null,
      updatedPreview: null,
      coordinatesSet: false,
      coordinatesChanges: false,
      previewFileExtension: "",
    };
  },
  watch: {
    cities(cities) {
      if (cities.length > 0 && !this.city) {
        this.city = cities[0]._id;
      }
    },
    city(city) {
      this.$store.dispatch("home/GET_CONTENT", { city: this.city });
      // this.setFeaturedContent()
    },
    "content.newContent"(file) {
      if (file) {
        this.content.content.media = URL.createObjectURL(file);
      }
    },
    "content.newThumbnail"(file) {
      if (file) {
        this.content.content.thumbnail = URL.createObjectURL(file);
        this.content.content.newThumbnail = URL.createObjectURL(file);
      }
    },
  },
  computed: {
    bottomFeatured() {
      return this.$store.state.home.bottomFeatured;
    },
    topFeatured() {
      return this.$store.state.home.topFeatured;
    },
    contentType() {
      return this.openedModal.toLowerCase();
    },
    cities() {
      return this.$store.state.cities.cities;
    },
    contents() {
      return this.$store.state.home.contents;
    },
  },
  methods: {
    onChangeMedia({ coordinates, canvas }) {
      const { cover } = this.$refs;

      if (this.actionType == "Update" && this.coordinatesSet == false) {
        if (this.content.content.previewCoordinates) {
          cover.setCoordinates(this.content.content.previewCoordinates);
        }
        // Set to true if the coordinates is set
        this.coordinatesSet = true;
      } else {
        // If the cropper is changed, store the coordinates and set
        this.coordinatesChanges = true;
        this.content.content.previewCoordinates = coordinates;
      }
      console.log("Updated Cover!!!");
      this.updatedPreview = this.dataURLtoFile(
        canvas.toDataURL(),
        this.content.newContent
          ? this.content.newContent.name
          : `${this.makeid(10)}-preview.${this.previewFileExtension}`
      );
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    makeid(length) {
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    },
    dataURLtoFile(dataurl, filename) {
      let name = filename ? filename : this.makeid(10);
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], name, { type: mime });
    },
    resetVariable() {
      this.content = {
        _id: null,
        city: null,
        content: {
          media: null,
          type: null,
          thumbnail: null,
          previewCoordinates: null,
        },
        position: null,
        newContent: null,
      };
      (this.updatedPreview = null),
        (this.coordinatesSet = false),
        (this.coordinatesChanges = false),
        (this.previewFileExtension = "");
    },
    composeVideo() {
      this.resetVariable();
      this.openedModal = "Video";
      this.modal = true;
    },
    composeImage() {
      this.resetVariable();
      this.openedModal = "Image";
      this.actionType = "Add";
      this.modal = true;
    },
    manageContent(content) {
      this.actionType = "Update";
      this.openedModal = content.content.type == "image" ? "Image" : "Video";
      content.content.previewCoordinates = content.content.previewCoordinates
        ? content.content.previewCoordinates
        : null;
      this.previewFileExtension = content.content.media
        .split(/[#?]/)[0]
        .split(".")
        .pop()
        .trim();
      this.content = JSON.parse(JSON.stringify(content));
      this.modal = true;
    },
    async deleteContent() {
      try {
        await this.$store.dispatch("home/DELETE_CONTENT", this.content._id);
        this.resetVariable();
        this.modal = false;
        this.$notify({
          message: "Content has been deleted.",
          timeout: 4000,
          type: "success",
        });
        // location.reload()
        this.loadContent(this.city);
      } catch (error) {
        this.$notify({
          message: error.message,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }
    },
    async uploadContent() {
      let canvas;
      let newThumbnail;

      // If 'New Video Thumbnail'
      if (this.openedModal == "Video") {
        canvas = this.$refs.cropper.getResult().canvas;
        newThumbnail = this.dataURLtoFile(
          canvas.toDataURL(),
          this.content.newThumbnail.name
        );
      }

      if (this.content.content.media && this.content.position != null) {
        let formData = new FormData();

        if (this.content.newContent) {
          if (this.openedModal == "Image") {
            formData.set("newContent_preview", this.updatedPreview);
            formData.set("newContent", this.content.newContent);
          } else {
            formData.set("newContent", this.content.newContent);
          }
        }

        if (this.content.newThumbnail) {
          formData.set("newThumbnail", newThumbnail);
          formData.set("newThumbnail_preview", this.content.newThumbnail);
        }

        delete this.content._id;
        this.content.city = this.city;
        this.content.content.type = this.contentType;

        // lowercase the following
        this.content.content.url = this.content.content.url
          ? this.content.content.url.replace(/^https/i, "https")
          : null;
        this.content.content.url = this.content.content.url
          ? this.content.content.url.replace(/^http/i, "http")
          : null;
        this.content.content.url = this.content.content.url
          ? this.content.content.url.replace(/www/i, "www")
          : null;

        console.log(this.content);
        formData.set("content", JSON.stringify(this.content));
        try {
          await this.$store.dispatch("home/CREATE_CONTENT", formData);
          this.resetVariable();
          this.modal = false;
          this.$notify({
            message: "Content has been uploaded.",
            timeout: 4000,
            type: "success",
          });
          this.loadContent(this.city);
        } catch (error) {
          this.$notify({
            message: error.message,
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "danger",
          });
        }
      } else {
        if (this.openedModal == "Video") {
          this.$notify({
            message: "Please select video, thumbnail and position",
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });
        } else {
          this.$notify({
            message: "Please select image and position",
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });
        }
      }
    },
    async updateContent() {
      let canvas;
      let newThumbnail;
      let formData = new FormData();

      // If update Video and Has newThumbnail
      if (this.openedModal == "Video" && this.content.newThumbnail) {
        canvas = this.$refs.cropper.getResult().canvas;
        newThumbnail = this.dataURLtoFile(
          canvas.toDataURL(),
          this.content.newThumbnail.name
        );
      }

      if (this.content.content.media && this.content.position != null) {
        if (this.content.newContent) {
          // If new uploaded newContent is Image
          if (this.openedModal == "Image") {
            formData.set("newContent", this.content.newContent);
            formData.set("newContent_preview", this.updatedPreview);
          } else {
            // If new uploaded newContent is Video
            formData.set("newContent", this.content.newContent);
          }
        }

        if (this.content.newThumbnail) {
          formData.set("newThumbnail", newThumbnail);
        }

        if (this.coordinatesChanges == true && !this.content.newContent) {
          formData.set("newContent_preview", this.updatedPreview);
        }

        // lowercase the following
        this.content.content.url = this.content.content.url
          ? this.content.content.url.replace(/^https/i, "https")
          : null;
        this.content.content.url = this.content.content.url
          ? this.content.content.url.replace(/^http/i, "http")
          : null;
        this.content.content.url = this.content.content.url
          ? this.content.content.url.replace(/www/i, "www")
          : null;

        formData.set("content", JSON.stringify(this.content));

        try {
          await this.$store.dispatch("home/UPDATE_CONTENT", formData);
          this.resetVariable();
          this.modal = false;
          this.$notify({
            message: "Content has been updated.",
            timeout: 4000,
            type: "success",
          });
          this.loadContent(this.city);
        } catch (error) {
          this.$notify({
            message: error.message,
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "danger",
          });
        }
      } else {
        if (this.openedModal == "Video") {
          this.$notify({
            message: "Please select video, thumbnail and position",
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });
        } else {
          this.$notify({
            message: "Please select image and position",
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });
        }
      }
    },
    async loadContent(city) {
      this.$store.dispatch("home/GET_CONTENT", { city: city });
    },
  },
  async created() {
    await this.$store.dispatch("cities/GET_CITIES");
  },
};
</script>

<style lang="scss" scoped>
.card > video {
  border-radius: 0.375rem;
}

.card > button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.divider-title {
  text-transform: capitalize;
  background: #2f2d2d;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 900;
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
    color: var(--primary);
    cursor: pointer;
  }
}

.toggleIcon {
  font-size: 1.3em;
}

.featureContainer {
  border: 2px solid #102038;
  padding: 10px;
  background: #f4f4f4;
}
</style>
