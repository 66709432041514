<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createConnectionContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Import Business</h3>
              </div>
            </div>

            <b-row id="processUsersForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-row v-if="loading">
                    <div class="col-lg-12 text-center">
                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                    </div>
                  </b-row>
                  <b-card-body v-else class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">
                            <div class="col-lg-12">
                              <div class="row">
                                <div class="col-lg-4">
                                  <b-form-group
                                    role="group"
                                    class="mb-3 has-feedback"
                                  >
                                    <label
                                      class="w-100 form-control-label"
                                      for="cover"
                                      >Import CSV</label
                                    >
                                    <b-form-file @change="loadCSV($event)">
                                    </b-form-file>
                                  </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                  <b-form-group
                                    role="group"
                                    class="mb-3 has-feedback"
                                  >
                                    <label
                                      class="w-100 form-control-label"
                                      for="plan"
                                      >Select plan
                                    </label>
                                    <b-form-select
                                      v-model="plan"
                                      :options="plans"
                                      name="plan"
                                      placeholder="Please select plan"
                                      v-validate="'required'"
                                      data-vv-as="plan"
                                      data-vv-name="plan"
                                      class="
                                        form-control
                                        no-instruction
                                        custom-select
                                      "
                                      :class="{
                                        'is-invalid': errors.has('plan'),
                                        'has-invalid': errors.has('plan'),
                                      }"
                                    >
                                    </b-form-select>
                                    <span class="has-icon-feedback"></span>
                                    <div
                                      class="invalid-feedback"
                                      :is-invalid="errors.has('plan')"
                                    >
                                      {{ errors.first("plan") }}
                                    </div>
                                  </b-form-group>
                                </div>
                                <div class="col-lg-4 register-button">
                                  <button
                                    :disabled="disableUpdate"
                                    type="button"
                                    id="processUsers"
                                    @click="importBusiness()"
                                    class="
                                      btn btn-primary
                                      float-right
                                      w-sm-100
                                      mt-3
                                    "
                                  >
                                    Submit
                                  </button>
                                </div>
                                <div class="duplicates" v-if="duplicateEmailList">{{duplicateEmailList}}</div>
                                <div v-if="submitted" class="col-lg-12 mt-5">
                                  <div
                                    class="
                                      d-flex
                                      align-items-center
                                      justify-content-center
                                    "
                                  >
                                    <div>
                                      <clip-loader
                                        :color="'#cbb169'"
                                        size="40px"
                                      ></clip-loader>
                                    </div>
                                    <div class="ml-2 loading-text">
                                      Processing...
                                    </div>
                                  </div>
                                </div>
                                <div v-else class="col-lg-12">
                                  <!-- <b-table
                                      v-if="!loading && businessUsers.length !=0"
                                      class="registerTable"
                                      id="registerTable"
                                      responsive
                                      hover
                                      :items="businessUsers"
                                      :fields="ListFields"
                                      :sort-by.sync="sortBy"
                                      :sort-desc.sync="sortDesc"
                                      :filter="searchRequest"
                                      :currentPage="currentPage"
                                      :per-page="perPage"
                                      head-variant="light"
                                      @filtered="onSearch"
                                      :filter-included-fields="['business', 'email']"
                                      @context-changed="loadInitialPendingVisibleItems"
                                  > -->
                                  <b-table
                                    v-if="!loading && businessUsers.length != 0"
                                    class="registerTable"
                                    id="registerTable"
                                    responsive
                                    hover
                                    :items="businessUsers"
                                    :fields="ListFields"
                                    head-variant="light"
                                  >
                                    <template v-slot:cell(actions)="data">
                                      <div class="data">
                                        <button
                                          type="button"
                                          @click="openModal(data.item)"
                                          class="
                                            btn btn-aqua
                                            float-right
                                            w-sm-100
                                            mb-3 mb-md-0
                                          "
                                        >
                                          View User Account
                                        </button>
                                      </div>
                                    </template>
                                  </b-table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <!-- <router-link ref="clear" :to="`/community/content/list`" exact class="btn btn-outline-aqua pr-5 pl-5 w-sm-100">Cancel</router-link> -->
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>

            <b-modal
              v-if="modal"
              v-model="modal"
              hide-header-close
              no-close-on-esc
              no-close-on-backdrop
              centered
            >
              <template v-slot:modal-title>
                <span style="color: #000; text-align: center"
                  >{{ currentModalData.business_name }}'s User Account</span
                >
              </template>
              <div class="modalData">
                <b-list-group-item
                  ><b>First Name</b>:
                  <span>{{
                    currentModalData.first_name
                  }}</span></b-list-group-item
                >
                <b-list-group-item
                  ><b>Last Name</b>:
                  <span>{{
                    currentModalData.last_name
                  }}</span></b-list-group-item
                >
                <b-list-group-item
                  ><b>Email</b>:
                  <span>{{
                    currentModalData.email
                      ? currentModalData.email
                      : currentModalData.email_address
                  }}</span></b-list-group-item
                >
                <b-list-group-item
                  ><b>Password</b>:
                  <span>{{
                    currentModalData.password
                  }}</span></b-list-group-item
                >
                <b-list-group-item
                  ><b>Phone Number</b>:
                  <span>{{
                    currentModalData.phone
                      ? currentModalData.phone
                      : currentModalData.phone_number
                  }}</span></b-list-group-item
                >
              </div>
              <template v-slot:modal-footer>
                <div style="flex: 1"></div>
                <base-button type="flat" @click="modal = false"
                  >Back</base-button
                >
              </template>
            </b-modal>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";

Vue.use(Cookies);

export default {
  data() {
    return {
      action: "create",
      ListFields: [
        {
          key: "business_name",
          label: "Business Name",
          sortable: true,
        },
        {
          key: "business_address",
          label: "Business Address",
          sortable: true,
        },
        {
          key: "business_email",
          label: "Business Email",
          sortable: true,
        },
        {
          key: "business_phone",
          label: "Business Phone Number",
          sortable: true,
        },
        {
          key: "short_description",
          label: "Business Short Description",
          sortable: true,
        },
        {
          key: "long_description",
          label: "Business Long Description",
          sortable: true,
        },
        {
          key: "website",
          label: "Business Website",
          sortable: true,
        },
        {
          key: "actions",
          label: "actions",
          tdClass: "actionsCol",
        },
      ],
      businessUsers: [],
      plan: null,
      disableUpdate: true,
      modal: false,
      currentModalData: undefined,
      formData: new FormData(),
      submitted: false,
      duplicateEmails: [],
      errorModal: false,
    };
  },
  computed: {
    plans() {
      let data = [];
      if (this.planList.length == 1) {
        data.push({ value: this.planList[0]._id, text: this.planList[0].name });
      } else {
        this.planList.map((plan) => {
          data.push({ value: plan._id, text: plan.name });
        });
      }

      return [
        { value: null, text: "Please select plan", disabled: true },
        ...data,
      ];
    },
    planList: {
      get: function () {
        return this.$store.state.subscriptions.plans;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.subscriptions.loading;
    },
    duplicateEmailList() {
      return this.duplicateEmails.length > 0 ? `Duplicate Emails: ${this.duplicateEmails.join(', ')}` : "";
    }
  },
  methods: {
    generatePassword() {
      var length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    openModal(data) {
      this.currentModalData = data;
      this.modal = true;
    },
    CSVToArray(strData, strDelimiter) {
      // Check to see if the delimiter is defined. If not,
      // then default to comma.
      strDelimiter = strDelimiter || ",";
      // Create a regular expression to parse the CSV values.
      var objPattern = new RegExp(
        // Delimiters.
        "(\\" +
          strDelimiter +
          "|\\r?\\n|\\r|^)" +
          // Quoted fields.
          '(?:"([^"]*(?:""[^"]*)*)"|' +
          // Standard fields.
          '([^"\\' +
          strDelimiter +
          "\\r\\n]*))",
        "gi"
      );

      // Create an array to hold our data. Give the array
      // a default empty first row.
      var arrData = [[]];

      // Create an array to hold our individual pattern
      // matching groups.
      var arrMatches = null;

      // Keep looping over the regular expression matches
      // until we can no longer find a match.
      while ((arrMatches = objPattern.exec(strData))) {
        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[1];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
          // Since we have reached a new row of data,
          // add an empty row to our data array.
          arrData.push([]);
        }

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
          // We found a quoted value. When we capture
          // this value, unescape any double quotes.
          var strMatchedValue = arrMatches[2].replace(
            new RegExp('""', "g"),
            '"'
          );
        } else {
          // We found a non-quoted value.
          var strMatchedValue = arrMatches[3];
        }

        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
      }

      // Return the parsed data.
      return arrData;
    },
    loadCSV(e) {
      var vm = this;

      this.duplicateEmails = [];

      // Reset Variable
      this.businessUsers = [];
      this.plan = null;

      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        // Handle errors load
        reader.onload = function (event) {
          var csv = event.target.result;
          var allRows = vm.CSVToArray(csv, ",");
          if (allRows[allRows.length - 1].length == 1) {
            allRows.pop();
          }
          for (var i = 1; i < allRows.length; i++) {
            var header = allRows[0].map((h) =>
              h.toLowerCase().replace(" ", "_")
            );
            var data = allRows[i];
            let user = {};

            for (var j = 0; j < header.length; j++) {
              user[header[j]] = data[j];
              user.password = vm.generatePassword();
            }
            vm.businessUsers.push(user);
          }
          vm.disableUpdate = false;
        };

        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert("FileReader are not supported in this browser.");
      }
    },
    importBusiness() {
      // Open Loader
      this.submitted = true;
      this.duplicateEmails = [];

      const promiseBtn = document.getElementById("processUsers");
      const bodyWrapper = document.getElementById("processUsersForm");
      promiseBtn.setAttribute("disabled", "disabled");
      bodyWrapper.classList.add("non-clickable");

      let users = this.businessUsers.map((value) => {
        return {
          business: {
            name: value.business_name,
            email: value.business_email,
            phone: value.business_phone,
            website: value.website,
            location: {
              address: {
                line1: value.business_address,
                line2: "",
              },
            },
            description: {
              short: value.short_description || "",
              long: value.long_description || "",
            },
            cover: {
              type: "image",
              url: "https://gamevillesportswear.com/wp-content/uploads/2020/03/placeholder-1.png",
            },
            images: [],
            status: "pending",
            adminNote: "",
          },
          user: {
            firstname: value.first_name,
            lastname: value.last_name,
            email: value.email ? value.email : value.email_address,
            password: value.password,
            phone: value.phone ? value.phone : value.phone_number,
          },
          plan: this.plan,
        };
      });

      console.log(users);
      this.formData.set("users", JSON.stringify(users));
      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch("user/REGISTER_USERS", this.formData)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Successfully Imported Business"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                location.reload();
              } else {
                if (res.data.error === "email_already_exist") {
                  let responseData = res.data.data;
                  responseData.map((data) =>
                    this.duplicateEmails.push(data.email)
                  );
                  this.errorModal = true;
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Email already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error importing business"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }
              this.submitted = false;
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              err.then((error) => {
                console.log(error);
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.submitted = false;
                this.hasError = true;
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              });
            });
        } else {
          this.submitted = false;
          promiseBtn.removeAttribute("disabled", "disabled");
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    getPlans() {
      this.$store.dispatch("subscriptions/GET_PLANS", {
        category: "imported-accounts",
      });
    },
  },
  watch: {},
  created() {
    this.getPlans();
  },
};
</script>
<style lang="scss">
.register-button {
  display: grid;
  place-content: center;
  justify-content: left;
}
.registerTable tr th:nth-child(4),
.registerTable tr td:nth-child(4) {
  width: 20% !important;
}
.registerTable tr th:nth-child(8),
.registerTable tr td:nth-child(8) {
  width: 20% !important;
}
.loading-text {
  font-weight: 900;
  text-transform: uppercase;
}

.duplicates {
    padding-left: 15px;
    font-weight: bold;
    color: var(--danger);
    margin-bottom: 15px;
}
</style>
