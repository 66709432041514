<template>
  <div class="videos">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <!-- HEADER -->
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Videos</h3>
          </div>
          <div class="col-lg-3 pull-left">
            <a
              @click.prevent="composeVideo"
              href="#"
              class="btn btn-aqua opacity-8 float-right"
            >
              <i class="fa fa-plus-circle"></i>
              New Video
            </a>
          </div>
        </div>

        <!-- MAIN CONTENTS -->
        <div class="card-body" v-if="$store.state.videos.loading">
          <pulse-loader color="var(--pulse-loader)"></pulse-loader>
        </div>
        <div class="card-body" v-else>
          <div class="form-group" style="max-width: 300px">
            <label class="form-control-label">Select City</label>
            <b-form-select
              v-model="city"
              :options="cities.map((c) => ({ text: c.city, value: c._id }))"
              required
            >
            </b-form-select>
          </div>

          <div class="row">
            <div class="col-lg-12" v-if="videos.length === 0">
              <p class="text-center">No videos yet.</p>
            </div>
            <div class="col-lg-4" v-for="(video, i) in videos" :key="i">
              <div class="card">
                <video
                  preload="metadata"
                  :src="video.url"
                  @mouseover="$event.target.controls = true"
                  @mouseleave="$event.target.controls = false"
                >
                  Your browser does not support video tag.
                </video>
                <button
                  class="btn btn-sm btn-white"
                  @click="manageVideo(video)"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>

    <b-modal v-model="modal" centered>
      <template v-slot:modal-title>
        <span style="color: #000">Video</span>
      </template>
      <div>
        <div class="form-group" v-if="video.url">
          <video preload="metadata" class="w-100" :src="video.url" controls>
            Your browser does not support video tag.
          </video>
        </div>

        <div class="form-group">
          <label class="form-control-label">
            {{ video._id ? "Change Video" : "Video" }}
          </label>
          <b-form-file
            v-model="video.newVideo"
            placeholder="Browse file or drop here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </div>
      </div>
      <template v-slot:modal-footer>
        <base-button
          type="danger"
          v-if="video._id"
          @click="deleteVideo"
          :disabled="$store.state.videos.loading"
        >
          Delete
        </base-button>
        <div style="flex: 1"></div>
        <base-button type="flat" @click="modal = false">Cancel</base-button>
        <base-button
          type="default"
          class="btn-aqua"
          @click="video._id ? updateVideo() : uploadVideo()"
          :disabled="$store.state.videos.loading"
        >
          {{ video._id ? "Update Video" : "Upload Video" }}
        </base-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      city: null,
      modal: false,
      video: {
        _id: null,
        city: null,
        url: null,
        newVideo: null,
      },
    };
  },
  watch: {
    cities(cities) {
      if (cities.length > 0 && !this.city) {
        this.city = cities[0]._id;
      }
    },
    city(city) {
      this.$store.dispatch("videos/GET_VIDEOS", { city: this.city });
    },
    "video.newVideo"(file) {
      if (file) {
        this.video.url = URL.createObjectURL(file);
      }
    },
  },
  computed: {
    cities() {
      return this.$store.state.cities.cities;
    },
    videos() {
      return this.$store.state.videos.videos;
    },
  },
  methods: {
    resetVideo() {
      this.video = {
        _id: null,
        city: null,
        url: null,
        newVideo: null,
      };
    },
    composeVideo() {
      this.resetVideo();
      this.modal = true;
    },
    manageVideo(video) {
      this.video = JSON.parse(JSON.stringify(video));
      this.modal = true;
    },
    async deleteVideo() {
      try {
        await this.$store.dispatch("videos/DELETE_VIDEO", this.video._id);
        this.resetVideo();
        this.modal = false;
        this.$notify({
          message: "Video has been deleted.",
          timeout: 4000,
          type: "success",
        });
      } catch (error) {
        this.$notify({
          message: error.message,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }
    },
    async uploadVideo() {
      let formData = new FormData();

      if (this.video.newVideo) {
        formData.set("newVideo", this.video.newVideo);
      }

      delete this.video._id;
      this.video.city = this.city;

      formData.set("video", JSON.stringify(this.video));

      try {
        await this.$store.dispatch("videos/CREATE_VIDEO", formData);
        this.resetVideo();
        this.modal = false;
        this.$notify({
          message: "Video has been uploaded.",
          timeout: 4000,
          type: "success",
        });
      } catch (error) {
        this.$notify({
          message: error.message,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }
    },
    async updateVideo() {
      let formData = new FormData();

      if (this.video.newVideo) {
        formData.set("newVideo", this.video.newVideo);
      }

      formData.set("video", JSON.stringify(this.video));

      try {
        await this.$store.dispatch("videos/UPDATE_VIDEO", formData);
        this.resetVideo();
        this.modal = false;
        this.$notify({
          message: "Video has been updated.",
          timeout: 4000,
          type: "success",
        });
      } catch (error) {
        this.$notify({
          message: error.message,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }
    },
  },
  async created() {
    await this.$store.dispatch("cities/GET_CITIES");
    await this.$store.dispatch("videos/GET_VIDEOS", { city: this.city });
  },
};
</script>

<style scoped>
.card > video {
  border-radius: 0.375rem;
}

.card > button {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>